/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import fbEvents from './static/fb.tracker';
// import googleAnalytics from './static/analytics';

import "@fontsource/montserrat/latin-400.css";
import "@fontsource/montserrat/latin-500.css";
import "@fontsource/montserrat/latin-600.css";
import "@fontsource/montserrat/latin-700.css";
import "@fontsource/montserrat/latin-900.css";

import "@fontsource/roboto/latin-300.css";
import "@fontsource/roboto/latin-400.css";
import "@fontsource/roboto/latin-500.css";
import "@fontsource/roboto/latin-700.css";

import "@fontsource/dm-sans";


export const onClientEntry = () => {
    fbEvents()
    // Currently set on seo.tsx, 
    // Should be activated here and removed from seo.tsx once tested and confirming new Analytics GA4 is configured correctly
    // googleAnalytics();
}